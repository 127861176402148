import { HTMLAttributes } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Button } from '@mantine/core';
import cn from 'classnames';
import { Path } from '@/shared/constants/links';
import styles from './volna-sale.module.scss';

interface VolnaSaleProps extends HTMLAttributes<HTMLDivElement> {}

export function VolnaSale({ className }: VolnaSaleProps) {
  const router = useRouter();

  const toLink = () => {
    router.push(Path.volnaSale);
  };

  return (
    <div className={cn(styles.card, className && className)} onClick={toLink}>
      <div className={styles.headerInfo}>
        <span className={styles.header}>Волна Sale</span>
        <div className={styles.infoCol}>
          <span className={styles.info}>Продай лишние гиги, минуты или SMS - купи, что нужно</span>
          {/* <span className={styles.info}>Биржа гигабайтов, минут и SMS.</span>
          <span className={styles.info}>Есть лишний ресурс?</span>
          <span className={styles.info}>Продайте ненужное, купите нужное</span> */}
        </div>
      </div>
      {/*Path.sale*/}
      <Link href={Path.volnaSale}>
        <Button className={cn('sale-btn', styles.button__root)}>Подробнее</Button>
      </Link>
    </div>
  );
}
